import React from "react";
import classNames from "classnames";

interface Props {
  // eslint-disable-next-line react/require-default-props
  wrapperClassName?: string;
  // eslint-disable-next-line react/require-default-props
  className?: string;
}

const DownloadIcon = ({
  wrapperClassName = undefined,
  className = undefined,
}: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10.983"
    height="13.337"
    viewBox="0 0 10.983 13.337"
    className={wrapperClassName}
  >
    <g
      id="Gruppe_272"
      data-name="Gruppe 272"
      transform="translate(2127.852 11.588)"
      className={classNames("icon--primary", "icon--fill-primary", className)}
    >
      <path
        id="Icon_material-file-download"
        data-name="Icon material-file-download"
        d="M18.483,9.207H15.345V4.5H10.638V9.207H7.5L12.992,14.7ZM7.5,16.268v1.569H18.483V16.268Z"
        transform="translate(-2135.352 -16.088)"
        className={classNames("icon--primary", "icon--fill-primary", className)}
      />
    </g>
  </svg>
);

export default DownloadIcon;
