import React from "react";
import classNames from "classnames";

function ChevronLeft({ wrapperClassName, className }) {
  return (
    <svg
      width="8.414"
      height="14.836"
      viewBox="0 0 8.414 14.836"
      className={wrapperClassName}
    >
      <path
        id="Chevron"
        className={classNames(
          "icon",
          "icon__chevron",
          "icon--small",
          className
        )}
        d="M0,6,6.007,0s5.99,5.987,6,6"
        transform="translate(1 13.422) rotate(-90)"
        fill="none"
        strokeLinecap="round"
        strokeWidth="2"
      />
    </svg>
  );
}

export default ChevronLeft;
