/* eslint-disable camelcase */
import React, { useState } from "react";
import zip from "lodash/zip";
import map from "lodash/map";
import classNames from "classnames";
import buttonize from "../../../utils/buttonize";
import ChevronRight from "../../../lib/icons/ChevronRight";
import DownloadLink from "../DownloadLink";
import FormWithCrossOriginWarning from "../FormWithCrossOriginWarning";
import OverridableForm, { OverridableFormProps } from "../OverridableForm";

export interface Feature {
  feature_title: string;
  value_int: number;
  value_string: string;
}

interface Product {
  asset_asset_key?: string;
  asset_product?: number;
  asset_sys_language_uid?: number;
  asset_uid?: number;
  asset_url?: string;
  asset_xml_descr?: string;
  asset_xml_mime_type?: string;
  asset_xml_purpose?: string;
  asset_xml_source?: string;
  category_category_key?: string;
  category_category_type?: number;
  category_sys_language_uid?: number;
  category_title?: string;
  category_uid?: number;
  features?: { [key: string]: Feature };
  product_baureihe?: number;
  product_classcode?: number;
  product_description?: string;
  product_sys_language_uid?: number;
  product_title: string;
  product_uid?: number;
  product_xml_supplier_aid: string;

  detail_page?: string;
}

interface Document {
  asset_uid?: number;
  asset_title: string;
  "asset_document_id ": string;
  asset_url: string;
}

type FilterState =
  | { [key: string]: { currentOption: { value: string | number | string[] } } }
  | undefined;

type ListOfProductAndFeatureValues<T> = (T[keyof T] | string)[];

interface ListRowExpandableProps<T> {
  values: ListOfProductAndFeatureValues<T>;
  tableAttributes: string[];
  product: Product | Document;
}

const ListRowExpandable: React.FunctionComponent<
  ListRowExpandableProps<Product | Document>
> = ({ values, tableAttributes, product }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <div
      className={classNames(
        "px-4",
        "mb-2",
        "product-filter__table__row",
        "product-filter__expandable",
        {
          collapsed: !isExpanded,
        }
      )}
      {...buttonize(() => setIsExpanded((prev) => !prev))}
    >
      <div className="row">
        {map(zip(values, tableAttributes), (value, i) => {
          const lastColumn = i + 1 === values.length;
          return (
            <div className="col-12 col-lg" key={`${value[0]}-${value[1]}`}>
              <div className="d-flex align-items-center justify-content-between">
                <span className="d-inline-block d-md-none font-weight-bold mr-2">
                  {`${value[1]}:`}
                </span>
                {value[0] ? <span>{value[0]}</span> : <em>N/A</em>}
                {lastColumn && (
                  <div className="product-filter__expandable-icon">
                    <div className="product-filter__expandable-icon__horizontal" />
                    <div className="product-filter__expandable-icon__vertical" />
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <div className="product-filter__expandable__content">
        <div className="pt-4">
          {"product_description" in product &&
            product.product_description !== undefined && (
              <p
                className="mb-3"
                dangerouslySetInnerHTML={{
                  __html: product.product_description,
                }}
              />
            )}
          {product.asset_url && "product_uid" in product && (
            <DownloadLink link={product.asset_url} />
          )}
        </div>
      </div>
    </div>
  );
};

interface ListRowProps<T> {
  values: ListOfProductAndFeatureValues<T>;
  tableAttributes: string[];
  link: string;
  filterState: FilterState;
  // eslint-disable-next-line react/require-default-props
  productListUrl?: string | undefined;
  contentUid: number;

  // eslint-disable-next-line react/no-unused-prop-types
  formComponent: OverridableFormProps["formComponent"];
}

const withoutPairsContainingUndefined = <FirstItemType, SecondItemType>(
  groupedItems: [FirstItemType | undefined, SecondItemType | undefined][]
) =>
  groupedItems.filter(
    (groupedItem): groupedItem is [FirstItemType, SecondItemType] =>
      groupedItem[0] !== undefined && groupedItem[1] !== undefined
  );

const ListRow: React.FunctionComponent<ListRowProps<Product | Document>> = ({
  values,
  tableAttributes,
  link,
  filterState = undefined,
  productListUrl,
  contentUid,
  formComponent = undefined,
}) => {
  const isProductServer = link
    ? link.includes("https://product.heidenhain.de/")
    : false;
  const isDocument = link ? link.includes(".pdf") : false;
  const language = window.document.documentElement.getAttribute("lang");
  const isNewTabLanguage = language !== "de-DE" && language !== "en-US";

  return (
    <OverridableForm
      method={isProductServer || isDocument ? "get" : "post"}
      action={link}
      className="pr-4 pl-0 mb-2 product-filter__table__row"
      target={
        isProductServer || isDocument || isNewTabLanguage ? "_blank" : "_self"
      }
      rel="noreferrer"
      formComponent={formComponent}
    >
      {filterState &&
        Object.entries(filterState).map(([key, value]) => (
          <input
            key={key}
            type="hidden"
            name={`tx_jhproducts_product[featureVals][${key}]`}
            value={value.currentOption.value}
          />
        ))}
      <input
        type="hidden"
        name="tx_jhproducts_product[productListUrl]"
        value={productListUrl}
      />
      <input
        type="hidden"
        name="tx_jhproducts_product[contentUid]"
        value={contentUid}
      />
      <button type="submit">
        <div className="product-filter__result-list">
          {map(
            withoutPairsContainingUndefined(zip(values, tableAttributes)),
            (keyToValuePair, i, listOfPairs) => {
              const key = keyToValuePair[1];
              const value = keyToValuePair[0];
              const lastColumn = i + 1 === listOfPairs.length;
              return (
                <div
                  className="product-filter__result-entry"
                  key={`${value}-${key}`}
                >
                  <div className="d-block d-md-flex align-items-center justify-content-between">
                    <span className="d-inline-block d-md-none font-weight-bold mr-2">
                      {`${key}:`}
                    </span>
                    <div className="d-flex justify-content-between w-100 align-items-center">
                      {value ? (
                        <span className="product-filter__result-value">
                          {value}
                        </span>
                      ) : (
                        <em>–</em>
                      )}
                      {lastColumn && (
                        <ChevronRight className="product-filter__result-icon icon--small icon--thin icon--primary" />
                      )}
                    </div>
                  </div>
                </div>
              );
            }
          )}
        </div>
      </button>
    </OverridableForm>
  );
};

interface Props<T> {
  product: T;
  // eslint-disable-next-line react/require-default-props
  isExpandable?: boolean;
  table: { product_fields: (keyof T)[]; features?: string[] };
  tableAttributes: string[];
  // eslint-disable-next-line react/require-default-props
  filterState?: FilterState;
  /**
   * extensions/jh_products/Classes/Controller/ProductController.php:206
   */
  // eslint-disable-next-line react/require-default-props
  productListUrl?: string;
  // eslint-disable-next-line react/require-default-props
  contentUid?: number;
}

const ProductFilterListRow: React.FunctionComponent<
  Props<Product | Document>
> = ({
  product,
  table,
  tableAttributes,
  isExpandable = false,
  filterState,
  productListUrl = undefined,
  contentUid = 0,
}) => {
  const productFeatures = "features" in product ? product.features ?? {} : {};
  const link = "detail_page" in product ? product.detail_page : undefined;
  const { product_fields, features } = table;
  const productValues = map(product_fields, (p) => product[p]);
  const featureValues = map(
    features,
    (id) => (productFeatures[id] || {})["value_string"]
  );

  const values = [...productValues, ...featureValues];
  return isExpandable ? (
    <ListRowExpandable
      values={values}
      product={product}
      tableAttributes={tableAttributes}
    />
  ) : (
    <ListRow
      link={link ?? product.asset_url ?? ""}
      values={values}
      tableAttributes={tableAttributes}
      filterState={filterState}
      productListUrl={productListUrl}
      contentUid={contentUid}
      formComponent={
        "asset_document_id " in product ? undefined : FormWithCrossOriginWarning
      }
    />
  );
};

export default ProductFilterListRow;
