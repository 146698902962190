import React from "react";
import classNames from "classnames";
import ChevronLeft from "../../lib/icons/ChevronLeft";
import ChevronRight from "../../lib/icons/ChevronRight";
import buttonize from "../../utils/buttonize";

const paginate = (currentPage, numberPages) => {
  const delta = 2;
  const left = currentPage - delta;
  const right = currentPage + delta + 1;
  const pageRange = [];
  const rangeWithDots = [];
  let l;

  for (let i = 1; i <= numberPages; i += 1) {
    if (i === 1 || i === numberPages || (i >= left && i < right)) {
      pageRange.push(i);
    }
  }

  pageRange.forEach((j) => {
    if (l) {
      if (j - l === 2) {
        rangeWithDots.push(l + 1);
      } else if (j - l !== 1) {
        rangeWithDots.push("...");
      }
    }
    rangeWithDots.push(j);
    l = j;
  });

  return rangeWithDots;
};

export default ({ currentPage, numberPages, setPage }) => {
  // window size 7
  const pageRange = paginate(currentPage, numberPages);
  return (
    numberPages > 1 && (
      <div className="d-flex justify-content-center align-items-center flex-wrap py-3">
        <span
          {...buttonize(() => setPage(currentPage - 1))}
          className={classNames("mr-4 pagination--prev-button", {
            "cursor-disabled": currentPage === 1,
          })}
          aria-disabled={currentPage === 1}
        >
          <ChevronLeft wrapperClassName="icon--dark" />
        </span>
        {pageRange.map((page) => {
          if (page === "...") {
            return (
              <span key={page} className="mx-3">
                ...
              </span>
            );
          }
          const isCurrent = page === currentPage;
          return (
            <span
              {...buttonize(() => setPage(page))}
              key={page}
              className={classNames(
                "font-weight-bold",
                "m-3",
                "pagination__page",
                { "text-primary": isCurrent }
              )}
            >
              {page}
            </span>
          );
        })}

        <span
          {...buttonize(() => setPage(currentPage + 1))}
          className={classNames("ml-4 pagination--next-button", {
            "cursor-disabled": currentPage === numberPages,
          })}
          aria-disabled={currentPage === numberPages}
        >
          <ChevronRight className="icon--dark" />
        </span>
      </div>
    )
  );
};
