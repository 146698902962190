import React from "react";
import "bootstrap/js/dist/modal";
import Modal from "react-bootstrap/Modal";
import classes from "./LicenseAgreementModel.styles.module.scss";
import licenseText from "./license-text.txt?raw"; // eslint-disable-line import/no-unresolved

interface Proprs {
  show: boolean;
  onHide: () => void;
  renderAction: () => JSX.Element;
}

const LicenseAgreementModal: React.FunctionComponent<Proprs> = ({
  show,
  onHide,
  renderAction,
}) => (
  <Modal
    size="xl"
    show={show}
    onHide={onHide}
    className={classes.licenseAgreementModel}
  >
    <Modal.Header>
      <Modal.Title>License Agreement</Modal.Title>
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <button
        type="button"
        className="subnav__btn--close btn text-white"
        onClick={onHide}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 13.859 13.859"
        >
          <g id="x" transform="translate(-3.45 0.265)">
            <path
              id="Pfad_13"
              data-name="Pfad 13"
              d="M0,.079,18.517,0"
              transform="translate(3.805 13.183) rotate(-45)"
              fill="none"
              stroke="#fff"
              strokeWidth="1"
            />
            <path
              id="Pfad_277"
              data-name="Pfad 277"
              d="M0,0,18.517.079"
              transform="translate(3.861 0.09) rotate(45)"
              fill="none"
              stroke="#fff"
              strokeWidth="1"
            />
          </g>
        </svg>
      </button>
    </Modal.Header>
    <Modal.Body>
      <p className="content mb-3">{licenseText}</p>
      {renderAction()}
    </Modal.Body>
  </Modal>
);

export default LicenseAgreementModal;
