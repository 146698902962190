import isNil from "lodash/isNil";
import castArray from "lodash/castArray";
import map from "lodash/map";

export const toQueryString = (obj) =>
  Object.keys(obj).reduce((str, key) => {
    if (isNil(obj[key])) {
      return str;
    }
    const delimiter = "&";
    const value = obj[key];
    const keyString = encodeURIComponent(key);
    const val = map(castArray(value), (v) => encodeURIComponent(v)).join(",");
    return [str, delimiter, keyString, "=", val].join("");
  }, "");
