import * as React from "react";
import {
  DetailedHTMLProps,
  FC,
  FormEvent,
  FormHTMLAttributes,
  MouseEventHandler,
  useMemo,
} from "react";
import * as ReactDOM from "react-dom";
import useTranslation from "../../lib/hooks/useTranslation";

type Props = DetailedHTMLProps<
  FormHTMLAttributes<HTMLFormElement>,
  HTMLFormElement
>;

const isCrossOrigin = (form: HTMLFormElement): boolean => {
  const targetUrl = new URL(form.action, window.location.href);

  return targetUrl.origin !== window.location.origin;
};

interface DialogProps {
  translations: Record<
    | string
    | "cross-origin-warning.close-button.label"
    | "cross-origin-warning.content",
    string
  >;
  targetUrl: URL;
  onCloseButtonClick: MouseEventHandler<HTMLButtonElement>;
}

const DEFAULT_CROSS_ORIGIN_WARNING_LOCALIZATION =
  "<p>The content you're trying to access is only available on the site: %1$s</p><p>If you're not automatically redirected, <button>click here</button>";

const readLocalization = (
  translations: Record<string, string>,
  key: string,
  alternative: string
) => {
  if (key in translations) {
    return translations[key];
  }

  // eslint-disable-next-line no-console
  console.warn(`Missing localization for key ${key}. `);
  return alternative;
};

const DialogContent: FC<DialogProps> = ({
  targetUrl,
  translations,
  onCloseButtonClick,
}) => {
  const content = useMemo(
    () =>
      readLocalization(
        translations,
        "cross-origin-warning.content",
        DEFAULT_CROSS_ORIGIN_WARNING_LOCALIZATION
      ).replaceAll("%1$s", targetUrl.host),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [translations["cross-origin-warning.content"], targetUrl]
  );

  return (
    <div className="form-with-cross-origin-warning__box">
      <div className="form-with-cross-origin-warning__box-head">
        <button onClick={onCloseButtonClick} type="button" data-role="abort">
          <img
            alt={readLocalization(
              translations,
              "cross-origin-warning.close-button.label",
              "Close Dialog"
            )}
            className="form-with-cross-origin-warning__close-icon"
            src="/typo3conf/ext/sitepackage/Resources/Public/Icons/Website/X_white.svg"
          />
        </button>
      </div>
      <div className="form-with-cross-origin-warning__box-body">
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </div>
  );
};

const FormWithCrossOriginWarning = (props: Props): JSX.Element => {
  const { children, onSubmit } = props;

  const translations = useTranslation();

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.persist();

    const target = e.target;
    if (!(target instanceof HTMLFormElement) || !isCrossOrigin(target)) {
      return onSubmit ? onSubmit(e) : undefined;
    }

    // TODO: resolve duplication
    const targetUrl = new URL(target.action, window.location.href);

    const dialogRoot = document.createElement("div");
    dialogRoot.classList.add("form-with-cross-origin-warning__backdrop");

    const submitTimeout = window.setTimeout(() => {
      target.submit();
    }, 3000);

    const handleAbort = () => {
      window.clearTimeout(submitTimeout);
      dialogRoot.remove();
    };

    ReactDOM.render(
      <DialogContent
        translations={translations}
        targetUrl={targetUrl}
        onCloseButtonClick={handleAbort}
      />,
      dialogRoot
    );

    window.document.body.append(dialogRoot);

    dialogRoot.querySelectorAll("button").forEach((button) => {
      button.classList.add(
        "link",
        "font-weight-bold",
        "info-item__link",
        "align-items-center",
        "bg-transparent",
        "border-0",
        "text-primary"
      );
      if (button.dataset["role"] !== "abort") {
        button.addEventListener("click", () => {
          if (onSubmit) {
            onSubmit(e);
          }
          target.submit();
        });
      }
    });

    e.preventDefault();
    return onSubmit ? onSubmit(e) : undefined;
  };

  return (
    <form onSubmit={handleSubmit} {...props}>
      {children}
    </form>
  );
};

export default FormWithCrossOriginWarning;
