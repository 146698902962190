import React, { DetailedHTMLProps, FC, FormHTMLAttributes } from "react";

type FormProps = DetailedHTMLProps<
  FormHTMLAttributes<HTMLFormElement>,
  HTMLFormElement
>;

export interface OverridableFormProps extends FormProps {
  // eslint-disable-next-line react/require-default-props,no-unused-vars
  formComponent?: FC<FormProps>;
}

const OverridableForm = ({
  formComponent,
  ...formProps
}: OverridableFormProps): JSX.Element => {
  if (formComponent === undefined) {
    return <form {...formProps} />;
  }

  const FormComponentOverride = formComponent;

  return <FormComponentOverride {...formProps} />;
};

export default OverridableForm;
