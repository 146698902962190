import "whatwg-fetch";
import productData from "../apiMock/productData.json";

const HEADERS =
  process.env.NODE_ENV === "development"
    ? new Headers({
        Authorization: "Basic aGlsbGVydEBOZXh0OjlCdVEhR2V4JF5tdXlIVyRNdA==",
      })
    : {};

export default ({ url = "", headers = {}, stubDev = false }) => {
  if (process.env.NODE_ENV === "development" && stubDev) {
    return Promise.resolve(productData);
  }
  const requestHeaders = {
    ...HEADERS,
    ...headers,
  };
  return fetch(url, { headers: requestHeaders }).then((res) => res.json());
};
