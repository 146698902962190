import React, { useState } from "react";
import LicenseAgreementModal from "./LicenseAgreementModal/LicenseAgreementModal";
import DownloadIcon from "../../lib/icons/DownloadIcon";
import useTranslation from "../../lib/hooks/useTranslation";
import buttonize from "../../utils/buttonize";

interface Props {
  link: string;
}

const DownloadLink = ({ link }: Props) => {
  const translations = useTranslation() as unknown as {
    accept_and_download_link?: string;
    download_link?: string;
  };
  const isModal = !/\.pdf$/.test(link);
  const [isModalOpen, setModalOpen] = useState(false);
  const hideModal = () => {
    setModalOpen(false);
  };

  const showModal = () => {
    setModalOpen(true);
  };
  // target="_blank"
  return (
    <>
      <LicenseAgreementModal
        show={isModalOpen}
        onHide={hideModal}
        renderAction={() => (
          <a
            href={link}
            target="_blank"
            rel="noreferrer"
            className="text-primary text-sm font-weight-bold"
          >
            <DownloadIcon wrapperClassName="mr-2" />
            {translations.accept_and_download_link ||
              "Lizenz akzeptieren und downloaden"}
          </a>
        )}
      />
      <div className="d-flex align-items-center">
        {isModal ? (
          <span
            {...buttonize(showModal)}
            className="text-primary text-sm font-weight-bold"
          >
            <DownloadIcon wrapperClassName="mr-2" />
            {translations.download_link}
          </span>
        ) : (
          <a
            target="_blank"
            rel="noreferrer"
            href={link}
            className="text-primary text-sm font-weight-bold"
          >
            <DownloadIcon wrapperClassName="mr-2" />
            {translations.download_link}
          </a>
        )}
      </div>
    </>
  );
};

export default DownloadLink;
