import { useState } from "react";

export default ({ collection = [], resultsPerPage = 20 }) => {
  const numberPages = Math.ceil((collection.length || 1) / resultsPerPage);
  const [currentPage, setPage] = useState(1);
  const currentCollection = collection.slice(
    (currentPage - 1) * resultsPerPage,
    currentPage * resultsPerPage
  );
  return [currentCollection, currentPage, numberPages, setPage];
};
