import React from "react";
import classNames from "classnames";

interface Props {
  // eslint-disable-next-line react/require-default-props
  wrapperClassName?: string;
  // eslint-disable-next-line react/require-default-props
  className?: string;
}

const ChevronRight = ({
  wrapperClassName = undefined,
  className = undefined,
}: Props) => (
  <svg
    width="8.414"
    height="14.836"
    viewBox="0 0 8.414 14.836"
    className={classNames("chevron-right", wrapperClassName)}
  >
    <path
      className={classNames("icon", "icon__chevron", "icon--small", className)}
      d="M0,6,6.007,0s5.99,5.987,6,6"
      transform="translate(7.414 1.414) rotate(90)"
      strokeWidth="2"
    />
  </svg>
);

export default ChevronRight;
